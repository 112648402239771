import { Grid } from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  WESER_EMS_AOK_PARTNER_LOGO,
  WESER_EMS_AOK_PARTNER_URL,
  WESER_EMS_BKK_PARTNER_LOGO,
  WESER_EMS_BKK_PARTNER_URL,
  WESER_EMS_CONSULTATION_URL,
  WESER_EMS_LOWER_SAXONY_MINISTRY_URL,
  WESER_EMS_VDEK_PARTNER_LOGO,
  WESER_EMS_VDEK_PARTNER_URL,
} from "core/consts";
import LinkV2 from "ds_legacy/components/LinkV2";
import { B2C_PRIMARY_COLOR, hexWithOpacity } from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  Body,
  Caption,
  FONT_SIZE_14,
  FONT_SIZE_16,
  FONT_SIZE_18,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_REGULAR,
  FONT_WEIGHT_SEMI_BOLD,
  Subheading,
} from "ds_legacy/materials/typography";
import Image from "dsl/atoms/Image";
import { useB2CNavigationUrls } from "dsl/hooks/useNavigationHandlers";
import { ArrowLeftIcon } from "lucide-react";
import React, { CSSProperties, ReactNode } from "react";
import { useTranslations } from "translations";
import { TranslationComposition } from "translations/helpers";
import Translations from "translations/types";
import { PAGES, PageWrapper, testIds } from "../common";

const LIST_STYLE = { margin: margin(0, 0, 2), padding: padding(0, 0, 0, 4) };

const BodyText = ({
  children,
  fontSize = FONT_SIZE_14,
  fontWeight = FONT_WEIGHT_REGULAR,
  noMargin,
}: {
  children: ReactNode;
  fontSize?: CSSProperties["fontSize"];
  fontWeight?: CSSProperties["fontWeight"];
  noMargin?: boolean;
}) => {
  return (
    <Body
      as="p"
      margin={noMargin ? margin(0) : margin(0, 0, 2)}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      {children}
    </Body>
  );
};

const BackLink = () => {
  const theme = useTheme();
  const translations = useTranslations();
  const { goToOnboarding } = useB2CNavigationUrls();

  return (
    <>
      <LinkV2
        data-testid="back-link"
        color="default"
        id="back"
        to={goToOnboarding({ page: PAGES.CARE_SOLUTION })}
        sx={{
          color: theme.palette.primary.main,
          cursor: "pointer",
          padding: padding(3, 0),
        }}
      >
        <HorizontalLayout aligned as="span" overflow="visible">
          <ArrowLeftIcon
            style={{ fontSize: FONT_SIZE_16, margin: margin(0, 1, 0, 0) }}
            size={FONT_SIZE_16}
          />
          {translations.providersearch.providerDetailsPage.backToListing}
        </HorizontalLayout>
      </LinkV2>
    </>
  );
};

const InformationSection = () => {
  const translations = useTranslations();
  return (
    <>
      <Subheading
        as="h3"
        style={{
          fontSize: FONT_SIZE_18,
          fontWeight: FONT_WEIGHT_BOLD,
          margin: margin(0, 0, 3),
        }}
      >
        {translations.bToC.onboardingScreens.dailyLivingSupportServices.title}
      </Subheading>
      <BodyText>
        {
          translations.bToC.onboardingScreens.dailyLivingSupportServices
            .pageContent.paragraphOne
        }
      </BodyText>
      <BodyText>
        {
          translations.bToC.onboardingScreens.dailyLivingSupportServices
            .pageContent.paragraphTwo
        }
      </BodyText>
      <BodyText>
        {
          translations.bToC.onboardingScreens.dailyLivingSupportServices
            .pageContent.paragraphThree
        }
      </BodyText>
      <ul style={LIST_STYLE}>
        <li>
          <BodyText noMargin>
            {
              translations.bToC.onboardingScreens.dailyLivingSupportServices
                .pageContent.bulletOne
            }
          </BodyText>
        </li>
        <li>
          <BodyText noMargin>
            {
              translations.bToC.onboardingScreens.dailyLivingSupportServices
                .pageContent.bulletTwo
            }
          </BodyText>
        </li>
        <li>
          <BodyText noMargin>
            {
              translations.bToC.onboardingScreens.dailyLivingSupportServices
                .pageContent.bulletThree
            }
          </BodyText>
        </li>
        <li>
          <BodyText noMargin>
            {
              translations.bToC.onboardingScreens.dailyLivingSupportServices
                .pageContent.bulletFour
            }
          </BodyText>
        </li>
      </ul>
      <BodyText>
        {
          translations.bToC.onboardingScreens.dailyLivingSupportServices
            .pageContent.paragraphFour
        }
      </BodyText>
      <ul style={LIST_STYLE}>
        <li>
          <BodyText noMargin>
            {
              translations.bToC.onboardingScreens.dailyLivingSupportServices
                .pageContent.bulletFive
            }
          </BodyText>
        </li>
        <li>
          <BodyText noMargin>
            {
              translations.bToC.onboardingScreens.dailyLivingSupportServices
                .pageContent.bulletSix
            }
          </BodyText>
        </li>
        <li>
          <BodyText noMargin>
            {
              translations.bToC.onboardingScreens.dailyLivingSupportServices
                .pageContent.bulletSeven
            }
          </BodyText>
        </li>
        <li>
          <BodyText noMargin>
            {
              translations.bToC.onboardingScreens.dailyLivingSupportServices
                .pageContent.bulletEight
            }
          </BodyText>
        </li>
      </ul>
      <TranslationComposition
        translations={
          translations.bToC.onboardingScreens.dailyLivingSupportServices
            .pageContent.paragraphFive
        }
        withOptions
      >
        {([before, link, after]) => (
          <BodyText>
            {before}
            <LinkV2
              href={WESER_EMS_LOWER_SAXONY_MINISTRY_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link}
            </LinkV2>
            {after}
          </BodyText>
        )}
      </TranslationComposition>
    </>
  );
};

type LogoMapItem = {
  image: string;
  label: string;
  link: string;
};

type LogoMap = {
  [key: string]: (translations: Translations) => LogoMapItem;
};

const LOGO_MAP: LogoMap = {
  aok: (translations: Translations) => ({
    label:
      translations.bToC.onboardingScreens.dailyLivingSupportServices.pageContent
        .linkAok,
    link: WESER_EMS_AOK_PARTNER_URL,
    image: WESER_EMS_AOK_PARTNER_LOGO,
  }),
  bkk: (translations: Translations) => ({
    label:
      translations.bToC.onboardingScreens.dailyLivingSupportServices.pageContent
        .linkBkk,
    link: WESER_EMS_BKK_PARTNER_URL,
    image: WESER_EMS_BKK_PARTNER_LOGO,
  }),
  vdek: (translations: Translations) => ({
    label:
      translations.bToC.onboardingScreens.dailyLivingSupportServices.pageContent
        .linkVdek,
    link: WESER_EMS_VDEK_PARTNER_URL,
    image: WESER_EMS_VDEK_PARTNER_LOGO,
  }),
};

const ProviderLinks = () => {
  const theme = useTheme();
  const translations = useTranslations();
  const items = Object.keys(LOGO_MAP).map((key) => LOGO_MAP[key](translations));
  return (
    <Grid
      container
      columnGap={1}
      rowGap={1}
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: padding(1.5),
        margin: margin(1.25, 0),
      }}
    >
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <Grid item xs={6}>
            <LinkV2
              href={item.link}
              sx={{ fontSize: FONT_SIZE_14 }}
              target="_blank"
            >
              {item.label}
            </LinkV2>
          </Grid>
          <Grid item xs={5}>
            <Image
              path={item.image}
              alt={`Logo ${item.label}`}
              style={{ height: sizing(3) }}
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

const ProviderSection = () => {
  const translations = useTranslations();
  return (
    <div
      style={{
        margin: margin(1, 0, 3),
        padding: padding(2),
        backgroundColor: hexWithOpacity(B2C_PRIMARY_COLOR, 10),
      }}
    >
      <Subheading
        as="h4"
        style={{
          fontSize: FONT_SIZE_16,
          fontWeight: FONT_WEIGHT_BOLD,
          margin: margin(0, 0, 3),
        }}
      >
        {
          translations.bToC.onboardingScreens.dailyLivingSupportServices
            .pageContent.bannerTitle
        }
      </Subheading>
      <TranslationComposition
        translations={
          translations.bToC.onboardingScreens.dailyLivingSupportServices
            .pageContent.bannerInformation
        }
        withOptions
      >
        {([before, link, after]) => (
          <BodyText>
            {before}
            <LinkV2
              href={WESER_EMS_CONSULTATION_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link}
            </LinkV2>
            {after}
          </BodyText>
        )}
      </TranslationComposition>
      <BodyText fontWeight={FONT_WEIGHT_SEMI_BOLD} noMargin>
        {
          translations.bToC.onboardingScreens.dailyLivingSupportServices
            .pageContent.bannerSubtitle
        }
      </BodyText>
      <ProviderLinks />
      <Caption
        whiteSpace="normal"
        style={{ fontWeight: FONT_WEIGHT_BOLD }}
        margin={margin(0)}
      >
        {
          translations.bToC.onboardingScreens.dailyLivingSupportServices
            .pageContent.hintText
        }
      </Caption>
    </div>
  );
};

export default function DailyLivingSupportServices() {
  return (
    <PageWrapper data-testid={testIds.dailyLivingSupportServices}>
      <VerticalLayout>
        <BackLink />
        <InformationSection />
        <ProviderSection />
      </VerticalLayout>
    </PageWrapper>
  );
}
